@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
    margin:0;
    padding:0;
    box-sizing: border-box;
    font-family: 'Poppins',sans-serif;
    scroll-behavior: smooth;
}

header{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    padding:40px 100px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    transition:0.5s;
    -webkit-transition:0.5s;
    -moz-transition:0.5s;
    -ms-transition:0.5s;
    -o-transition:0.5s;
}

header .logo{
    color:#fff;
    font-size:24px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 700;
    letter-spacing: 2px;
}

header ul{
    position: relative;
    display:flex;
}

header ul li{
    position:relative;
    list-style: none;
}

header ul li a{
    position: relative;
    display:inline-block;
    margin:0 15px;
    color:#fff;
    text-decoration: none;
}



/* Creating Stiky Header Menu Nav Bar */
header.sticky{
    background:#fff;
    padding:7px 100px;
    box-shadow:0 5px 20px rgba(0,0,0,0.1);
}

header.sticky .logo{
    color:#111;
}

header.sticky ul li a{
    color:#111;
}



/* Creating Section Layout */
section{
    padding:80px;
}



/* Creating Banner Layout */
.banner{
    background: #111;
    padding-top: 175px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.banner h2{
    font-size: 2.5em;
    color:#444;
    font-weight: 300;
    line-height: 1.5em;
}

.banner h2 span{
    font-size: 1.2em;
    font-weight: 300;
    color:#efefef;
}

.banner h3{
    color:#fff;
    font-size: 1.5em;
    font-weight: 500;
}

.btn{
    position:relative;
    background:#009e66;
    display:inline-block;
    color:#000;
    margin-top: 20px;
    padding:10px 30px;
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 2px;
    font-weight: 500;
    border-radius: 30px;
}

.proPic1{
    width:300px;
    height:350px;
    margin-right : 75px;
    border-bottom-right-radius : 30%;
    border-top-left-radius: 30%;
    border-top-right-radius: 30%;
}



/* Creating About Layout */
.content{
    display:flex;
    align-items: center;
    justify-content: space-between;
}

.heading{
    width:100%;
    text-align: center;
    margin-bottom: 30px;
    color:#111;
}

.heading p{
    font-style: italic;
    font-size:14px;
}

.heading h2{
    font-size: 3em;
    color:#009e66
}

.contentBx{
    text-align: justify;
}

.contentBx h2{
    font-weight: 300;
    margin-bottom: 10px;
}

.proPic{
    height:265px;
}

.heading.bottom{
    display:flex;
    justify-content: space-between;
    flex-direction:row;
    align-items: center;
    text-align: left;
    margin: 0;
}

.btn.round{
   border-radius:50px;
}

.info{
    display:flex;
    justify-content: space-between;
}

.bt{
    margin-top: 20px;;
}

.w50{
    display: contents;
}

img{
    max-width:100%;
}



/* Creating Projects layout */
.projects{
    background:#111;
}

.heading.white p{
    color:#fff;
}

.projects .content{
    display:flex;
    justify-content: center;
    flex-wrap:wrap;
    flex-direction:row;
}

.projects .content .projectsBx{
    color:#fff;
    background:#161616;
    padding:30px 15px;
    max-width: 360px;
    margin:10px 15px;
    text-align: center;
}

.projects .content .projectsBx:hover{
    border-bottom:2px solid #009e66;
    cursor:pointer;
}

.projects .content .projectsBx .fa{
    margin-bottom: 10px;
    color:#009e66;
}

.projects .content a{
    text-decoration: none;
}






/* Creating Blog Layout in CSS */
.blog .content{
    display:flex;
    justify-content: center;
    flex-wrap:wrap;
    flex-direction:row;
}

.blog .content .blogBx{
    background:#fff;
    padding:60px 40px;
    margin:20px;
    /* max-width:calc(50% - 40px); */
    /* border-bottom-left-radius: 20%;
    border-bottom-right-radius: 20%; */
    border-radius: 15px;
    box-shadow: 0px 2px 3px 2px #00000026;
}

.blog .content .blogBx:hover{
    border-bottom:2px solid #009e66;
    cursor:pointer;
}

.blog .content a{
    text-decoration: none;
    max-width: calc(50% - 40px);
}

.blog .content .blogBx p{
    color:#111;
    font-size:18px;
    font-weight: 300;
    line-height: 24px;
}

.blog .content .blogBx h3{
    margin-top: 40px;
    color:#009e66;
    font-weight: 500;
    line-height: 1.7em;
    font-size: 20px;
    text-transform: uppercase;
}

.blog .blogfooter{
    display: flex;
    justify-content: flex-end;
}

.blog .blogfooter a{
    color: #009e66;
    font-size: 22px;
}



/* Creating Contact Details Layout in CSS */
.contact{
    background:#111;
}

.contact.content{
    align-items: flex-start;
    flex-direction: row;
}

.formBx{
    min-width:60%;
}

.formBx form{
    display:flex;
    flex-direction:column;
}

.formBx form h3,
.contactInfo h3{
    color:#fff;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
}

.formBx form input,
.formBx form textarea{
    margin-bottom: 20px;
    padding:15px;
    font-size: 16px;
    background:transparent;
    border:none;
    outline:none;
    background:#161616;
    resize:vertical;
    color:#fff;
}

.formBx form input:focus,
.formBx form textarea:focus{
    transition:0.5s;
    -webkit-transition:0.5s;
    -moz-transition:0.5s;
    -ms-transition:0.5s;
    -o-transition:0.5s;
    border-bottom:1px solid #009e66;
}

.formBx form input::-webkit-input-placeholder, .formBx form textarea::-webkit-input-placeholder{
    color:#999;
}

.formBx form input::placeholder,
.formBx form textarea::placeholder{
    color:#999;
}

.contact.content .formBx .btn {
    display: flex;
    max-width: -webkit-max-content;
    max-width: max-content;
    margin: 0;
}

.formBx form input[type="submit"]{
    background:#009e66;
    max-width:250px;
    cursor:pointer;
    font-weight: 600;
    letter-spacing: 2px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    margin: 0;
    padding: 5px;
}

.contactInfo{
    min-width:40%;
}

.contactInfo p{
    color:#999;
}

.contactInfoBx{
    position:relative;
}

.contactInfoBx .box{
    position:relative;
    padding:20px 0;
    display:flex;
}

.contactInfoBx .box .icon{
    color:#fff;
    min-width:40px;
    padding-top:4px;
    display:flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 24px;
}

.contactInfoBx .box .text{
    color:#999;
    margin-left:20px;
    font-size: 16px;
    flex-direction: column;
    font-weight: 300;
}

.contactInfoBx .box .text h3{
    font-weight: 500;
    color:#009e66;
    margin-bottom: 0;
}

.contactInfoBx .box .text p{
    font-weight: 300;
    color:#999;
}

.contactInfoBx .boxSocial{
    display:flex;
    flex-direction:row;
    letter-spacing: 15px;
    padding-left:60px;
}

.contactInfoBx .boxSocial .fa{
    color:#009e66;
    font-size: 42px;
    cursor:pointer;
    
}

footer.copyright{
    background:#000;
    color:#fff;
    text-align: center;
    padding:10px;
}

.copyright a{
    color: #fff;
}









@media (max-width: 991px)
{
    header{
        padding:20px 50px;
		z-index:2;
    }

    header.sticky{
        padding:0px 35px;
		z-index:2;
    }

    header .menu{
        display:none;
        z-index:1;
    }

    header .menu{
        position:fixed;
        background:#fff;
        display:block;
        top:75px;
        left:-100%;
        padding:100px 50px;
        width:100%;
        height:100vh;
        border-top:1px solid rgba(0,0,0,0.2);
        text-align:center;
        transition:0.5s;
        -webkit-transition:0.5s;
        -moz-transition:0.5s;
        -ms-transition:0.5s;
        -o-transition:0.5s;
    }

    header .menu.active{
        position: fixed;
        top: 75px;
        left: 0;
        padding: 100px 50px;
        display: block;
        text-align: center;
        width: 100%;
        height: 100vh;
        background: #fff;
    }

    header ul li a{
        font-size: 20px;
        font-weight: 400;
        color:#111;
        margin:10px 0;
    }

    .toggle{
        width:40px;
        height:40px;
        background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAAfCAYAAAB3XZQBAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAsSAAALEgHS3X78AAAAM0lEQVRYw+3WsREAIAzEMML+Oz8FQxAu0gQuXUmyPrVfB4gHALjKVYofFg8AbRkz8dPiD+qQDAquHlwPAAAAAElFTkSuQmCC);
        background-position:center;
        background-size:30px;
        background-repeat:no-repeat;
        cursor:pointer;
    }

    .toggle.active{
        width:40px;
        height:40px;
        background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAmCAYAAACsyDmTAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAsSAAALEgHS3X78AAACtElEQVRYw8WYS09aURSFLyYaNURFp471L/g3BEkqDpSaqgmQGGwg7aAd+BcVaTRq1BgfpK0V46OpXwfsq8fNVrhwL67kDODsvdY6j3tenicAckDC6zGABJDTfxZpIP8OhvKiXfT/2OQZFWCuh2bmRNPHpgc88BKPQBkYjtDIsGg8Ku0HD0gDh6riBEhFaCglGi4OgbQHDAIlmnEkc2siRCMTwnlk6JWAQT8wJuO5pYL+AcshGloWThdboh2zElaN+bQNzD+578zIoHBsG/Nm9a3EBFAA9owu/Qz0dWCmT3I19kSr9donrbk0CGY7MDRrNPASmA9CMgp8BeqKqCaf63gbHOMSW1McdeEeDdo4D0gCVUX4B1hoI3dBYl1UgWRgI4p43Rj/KvAJGDHiR6SuauStd2VGBOLAR14u8T4KRnzBiKsIR7xrQ47QInCjhHbcIZAh3lExN8BiaEYcMX+SnivBO+C7lDtVd97uR9CNsRSwr4R/S3GxT4T7oWuoH3vv0ygB/ZEbckx9AE4NI6dS15GZwFuB7ymkmFB6ZyDAkA30wlAKOFDiv6S4OIh0Ujuf/ZkSvge+SblXdWeRffbAEnCrBCs0L4x6Rb8FlsI0Egey2FtH3ojPG3EV4eh+6+D5zubiB7CCcYSgcXRZkRiNYrdmkgbxFe0fP66MhgQ/fgBjwBfgWhH+lEna8thJ4zhclhwX18I9FsRQhuaT3i6dH2F3FVcNyLTbMzmDAGAD66rSmjMmuRgNzL3ZU8CaSvKvQRlgKKgZh3dIOKxr0NprSdZF8S+Q7dSIoZEVThdbuA8cwKRMPo1j6erQVlkaq/2GcGuUgUkPmKH5unNM9I8N2lQdmPGfRi4Mt714jnFx8aQJTNN4HqkA6aiMGMbSonkCTOvKKfRbX29M5YCp1yrf5dHT/f0fUIQ0KuOi/GAAAAAASUVORK5CYII=);
        background-position:center;
        background-size:25px;
        background-repeat:no-repeat;
        cursor:pointer;
    }

    header.sticky .toggle{
        filter:invert(1);    ;
        -webkit-filter:invert(1)    ;
    }

    section {
        padding: 100px 30px;
    }

    .banner{
        padding: 150px 50px 100px;
        flex-direction: column;
    }

    .banner h2{
        font-size: 1.5em;
    }

    .banner h3{
        font-size: 1em;;
    }

    .textBx{
        text-align: center;
        margin-bottom: 30px;
    }

    .proPic1{
        margin: 0;
        border-radius: 45%;
    }

    .btn{
        margin-top: 10px;
        padding: 10px 20px;
        font-size: 16px;
        border-radius:50px;
        -webkit-border-radius:50px;
        -moz-border-radius:50px;
        -ms-border-radius:50px;
        -o-border-radius:50px;
    }

    .content{
        flex-direction: column;
    }

    .contentBx{
        padding:0;
    }

    .content .w50 img{
        display:block;
        margin:0 auto 30px auto;
    }

    .heading h2{
        font-size:24px;
    }

    .contentBx h3{
        font-size: 20px;
    }

    .contentBx h2{
        font-size: 20px;
        font-weight: 300;
    }

    .projects .content .projectsBx{
        margin:10px;
    }

    .contactInfo{
        margin:10px 0;
    }
}









@media (max-width: 768px){
    .blog .content .blogBx{
        max-width: calc(100% - 20px);
        padding: 40px 20px;
        margin: 30px;
    }

    .heading.bottom{
        display:flex;
        justify-content: space-between;
        align-items: center;
        flex-direction:row;
    }

    .blog .content a{
        max-width: calc(100% - 40px);
    }
    
    .contact.content{
        padding-left: 14%;
        flex-direction: column;
    }

    .formBx{
        min-width:85%;
        max-width: 85%;
    }

    .submit{
        display: flex;
        justify-content: center;
    }
}









@media (max-width: 600px){
    .blog .content .blogBx{
        max-width: calc(100% - 20px);
        padding: 40px 20px;
        margin: 30px;
    }

    .heading.bottom{
        display:flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction:column;
    }

    .contact.content .formBx .btn{
        align-items: center;
        justify-content: center;
    }
}



/* Chatbot Section */

*, html {
    --primaryGradient: linear-gradient(93.12deg, #009e67c0 0.52%, #009e66 100%);
    --secondaryGradient: linear-gradient(268.91deg, #009e67c0 -2.14%, #009e66 99.69%);
    --primaryBoxShadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
    --secondaryBoxShadow: 0px -10px 15px rgba(0, 0, 0, 0.1);
    --primary: #009e66;
}

/* CHATBOX
=============== */
.chatbox {
    position: fixed;
    bottom: 30px;
    right: 30px;
}

/* CONTENT IS CLOSE */
.chatbox__support {
    display: flex;
    flex-direction: column;
    background: #eee;
    width: 300px;
    height: 350px;
    z-index: -123456;
    opacity: 0;
    transition: all .25s ease-in-out;
}

.chatbox--hidden {
    display: none;
}

/* CONTENT ISOPEN */
.chatbox--active {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
    z-index: 123456;
    opacity: 1;
}

/* BUTTON */
.chatbox__button {
    text-align: right;
}

.send__button {
    padding: 6px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}


/* HEADER */
.chatbox__header {
    /* position: sticky; */
    top: 0;
    background: orange;
}

/* MESSAGES */
.chatbox__messages {
    margin-top: auto;
    display: flex;
    overflow-y: scroll;
    flex-direction: column-reverse;
}

.messages__item {
    background: #E0E0E0;
    max-width: 85%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    word-wrap: break-word;
    white-space: pre-line;
}

.messages__item--operator {
    margin-right: auto;
    text-align: left;
}

.messages__item--visitor {
    margin-left: auto;
    text-align: left;
}

/* FOOTER */
.chatbox__footer {
    /* position: sticky; */
    bottom: 0;
}

.chatbox__support {
    background: #f9f9f9;
    height: 450px;
    width: 350px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

/* HEADER */
.chatbox__header {
    background: var(--primaryGradient);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: var(--primaryBoxShadow);
}

.chatbox__image--header {
    margin-right: 10px;
}

.chatbox__heading--header {
    font-size: 1.2rem;
    color: white;
}

.chatbox__description--header {
    font-size: .9rem;
    color: white;
}

/* Messages */
.chatbox__messages {
    padding: 0 20px;
}

.messages__item {
    margin-top: 10px;
    background: #E0E0E0;
    padding: 8px 12px;
    max-width: 85%;
}

.messages__item--visitor,
.messages__item--typing {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.messages__item--operator {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    background: var(--primary);
    color: white;
}

/* FOOTER */
.chatbox__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px;
    background: var(--secondaryGradient);
    box-shadow: var(--secondaryBoxShadow);
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-top: 20px;
}

.chatbox__footer input {
    width: 80%;
    border: none;
    padding: 10px 10px;
    border-radius: 30px;
    text-align: left;
}

.chatbox__send--footer {
    color: white;
}

.chatbox__button button,
.chatbox__button button:focus,
.chatbox__button button:visited {
    padding: 10px;
    background: white;
    border: none;
    outline: none;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}
